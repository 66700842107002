import { createStore } from 'vuex'
import { getAuthInfo, getWalletList, getWalletUserNotifications } from '../services/api'
import { handleAsyncError } from '../services/errorHandler'



export default createStore({
  state: {
    sendConfirmCode: false,
    phone: '',
    authToken: localStorage.getItem("authToken"),
    walletList: null,  
    authInfo: null,
    userNotifications: null,
  },
  getters: {
  },
  mutations: {
    logout(state) {
      state.authToken = null
      localStorage.removeItem('authToken')
    },      
    setAuthToken(state, authToken) {
      state.authToken = authToken
      localStorage.setItem('authToken', authToken)
    },    
    ConfirmCode(state) {
      state.sendConfirmCode = !state.sendConfirmCode
    },
    changePhoneNumber (state, phone) {
      state.phone = phone
    },


    // API update
    updateWalletList(state, walletList) {
        state.walletList = walletList
    },
    updateAuthInfo(state, authInfo) {
      state.authInfo = authInfo
    },
    updateUserNotifications(state, userNotifications) {
      state.userNotifications = userNotifications
      console.log("userNotifications", userNotifications)
    }
  },
  actions: {
    // API requests
    async refreshWalletList(context) {
      handleAsyncError( 
        async function() {
          context.commit("updateWalletList", await getWalletList(context.state.authToken))  
        }
      ) ()
    },
    async refreshAuthInfo(context) {
      handleAsyncError( 
        async function() {      
          context.commit("updateAuthInfo", await getAuthInfo(context.state.authToken))  
        }
      ) ()
    },
    async refreshUserNotifications(context) {
      handleAsyncError( 
        async function() {
          context.commit("updateUserNotifications", await getWalletUserNotifications(context.state.authToken))  
        }
      ) ()
    }
  },
  modules: {
  }
})
