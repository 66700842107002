import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './plugins/utils'
import api from './plugins/api'
import '../src/assets/style.css'
import '../src/assets/responsive.css'
import moment from 'moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


const app = createApp(App)
    .use(moment)
    .use($)
    .use(store)
    .use(VueAxios, axios)
    .use(router)
    .use(utils)
    .use(api)


app.config.errorHandler = (err, vm, info) => {
    console.error("Error:", err);
    console.error("Vue component:", vm);
    console.error("Additional info:", info);
};



// Global uncaught promise rejection handler
window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled Rejection:', event.reason);
  
    // Render the error page component
    // renderErrorPage(event.reason.message || 'An unexpected error has occurred.');
  });
  

app.mount('#app')



    