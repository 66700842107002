

export function handleAsyncError(fn) {
    return function (...args) {
      return fn(...args).catch((error) => {

        console.error('Unhandled Error:', error);

        // Manually trigger the global error handler 
        /*
        if (this.$root && this.$root.$options.errorHandler) {
          this.$root.$options.errorHandler(error, this, fn.name);
        } else {
          console.error('Unhandled Error:', error);
        }*/

      });
    };
  }